<template>
  <rtb-card ma-4 d-flex class="system-page">
    <rtb-card-body>
      <weve-text-field
        label="Open Session ID"
        v-model="defaultOpenSessionIdComputed"
        placeholder="ID"
      />
      <weve-text-field
        class="mt-3"
        label="Banner Media URL"
        v-model="bannerMediaUrlComputed"
        placeholder="example.com"
      />
    </rtb-card-body>
  </rtb-card>
</template>

<script>
import { WeveTextField } from "@weve/ui"
import { RtbCard, RtbCardBody } from "@/components/ui"
import useSystem from "@/use/useSystem"

export default {
  name: "System",
  components: {
    WeveTextField,
    RtbCard,
    RtbCardBody
  },
  setup() {
    const {
      bannerMediaUrl,
      bannerMediaUrlRef,
      defaultOpenSessionId,
      defaultOpenSessionIdRef
    } = useSystem()
    return {
      bannerMediaUrl,
      bannerMediaUrlRef,
      defaultOpenSessionId,
      defaultOpenSessionIdRef
    }
  },
  computed: {
    bannerMediaUrlComputed: {
      get() {
        return this.bannerMediaUrl
      },
      set(value) {
        this.bannerMediaUrlRef.set(value)
      }
    },
    defaultOpenSessionIdComputed: {
      get() {
        return this.defaultOpenSessionId
      },
      set(value) {
        this.defaultOpenSessionIdRef.set(value)
      }
    }
  }
}
</script>
