var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "system-page", attrs: { "ma-4": "", "d-flex": "" } },
    [
      _c(
        "rtb-card-body",
        [
          _c("weve-text-field", {
            attrs: { label: "Open Session ID", placeholder: "ID" },
            model: {
              value: _vm.defaultOpenSessionIdComputed,
              callback: function ($$v) {
                _vm.defaultOpenSessionIdComputed = $$v
              },
              expression: "defaultOpenSessionIdComputed",
            },
          }),
          _c("weve-text-field", {
            staticClass: "mt-3",
            attrs: { label: "Banner Media URL", placeholder: "example.com" },
            model: {
              value: _vm.bannerMediaUrlComputed,
              callback: function ($$v) {
                _vm.bannerMediaUrlComputed = $$v
              },
              expression: "bannerMediaUrlComputed",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }