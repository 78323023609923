import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  Ref
} from "@vue/composition-api"

import { db } from "@/firebase"

type System = {
  defaultOpenSessionId?: string
  bannerMediaUrl?: string
}

const consumersCount = ref(0)
const system: Ref<System> = ref({})

const enabled = computed(() => consumersCount.value > 0)

const systemRef = computed(() => {
  if (enabled.value === false) return null
  return db.ref(`system`)
})

const defaultOpenSessionIdRef = computed(() =>
  systemRef.value?.child(`defaultOpenSessionId`)
)
const defaultOpenSessionId = computed(() => system.value?.defaultOpenSessionId)

const bannerMediaUrlRef = computed(() =>
  systemRef.value?.child(`bannerMediaUrl`)
)
const bannerMediaUrl = computed(() => system.value?.bannerMediaUrl)

function onSystemSnapshot(snapshot) {
  system.value = snapshot.val()
}

watch(
  systemRef,
  (newRef, oldRef) => {
    oldRef?.off("value", onSystemSnapshot)
    newRef?.on("value", onSystemSnapshot)
  },
  { immediate: true }
)

export default function useMissions() {
  onMounted(() => {
    consumersCount.value++
  })

  onBeforeUnmount(() => {
    consumersCount.value--
  })

  return {
    defaultOpenSessionId,
    defaultOpenSessionIdRef,
    bannerMediaUrl,
    bannerMediaUrlRef
  }
}
